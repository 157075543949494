<template>
  <div>
    <div class="title">高光 时刻</div>
    <div class="typechage">
      <div class="changebox">
        <div
          class="typeitem"
          v-for="item in typelist"
          :key="item.id"
          @click="changeindex(item.id)"
          :class="{ actypeitem: item.id == acindex }"
        >
          {{ item.title }}
        </div>
      </div>
    </div>
    <div class="listbox" v-show="acindex == 1">
      <div class="items" v-for="item in getList" :key="item.id">
        
        <div class="itemsprice">
          <Cprice :price="item.skins_price" />
        </div>
        <div class="itemsgoodsimg">
          <img :src="item.skins_img" alt="" />
        </div>
        <div class="itemsinfo">
          <div class="goodsname">{{ item.skins_name }}</div>
        </div>
      </div>
    </div>
    <div class="listbox" v-show="acindex == 2">
      <div class="items" v-for="item in getperformlist" :key="item.id">
        <div class="itemsprice">
          <Cprice :price="item.bean" />
        </div>
        <div class="itemsgoodsimg">
          <img :src="item.cover" alt="" />
        </div>
        <div class="itemsinfo">
          <div class="goodsname">{{ item.name }}</div>
          <div class="goodsname">{{ item.dura_alias }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewlyHighLightApi, getPerformanceApi } from "@/network/api.js";

export default {
  data() {
    return {
      typelist: [
        {
          id: 1,
          title: "辉煌时刻",
        },
        {
          id: 2,
          title: "最近获得",
        },
      ],
      acindex: 1,
      getList: [],
      getperformlist: [],
    };
  },
  created() {
    this.getNewlyHighLight();
    this.getPerformance();
  },
  methods: {
    changeindex(v) {
      this.acindex = v;
    },

    // 辉煌时刻
    getNewlyHighLight() {
      getNewlyHighLightApi().then((res) => {
        this.getList = res.data.data;
      });
    },
    // 最近获得
    getPerformance() {
      getPerformanceApi().then((res) => {
        this.getperformlist = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.typechage {
  width: 100%;
  height: 0.58rem;
  display: flex;
  justify-content: center;
  align-items: center;
  .changebox {
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0.38rem;
    background: #161514;
    .typeitem {
      width: 0.96rem;
      height: 0.3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
    }
    .actypeitem {
      background: url("../../assets/newImages/send-code-bg.png") no-repeat;
      background-size: 100% 100%;
      color: #151413;
    }
  }
}
.listbox {
  width: 3.35rem;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  .items {
    width: 1.07rem;
    height: 1.07rem;
    background: #1d1300;
    border: 0.01rem solid #69552d;
    margin-right: 0.0465rem;
    margin-top: 0.05rem;
    .itemsprice {
      width: 100%;
      height: 0.2rem;
      display: flex;
      justify-content: center;
    }
    .itemsgoodsimg {
      width: 100%;
      height: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 0.8rem;
        height: 0.6rem;
      }
    }
    .itemsinfo {
      width: 100%;
      font-weight: 400;
      font-size: 0.1rem;
      color: #ffffff;
    
      .goodsname {
        width: 80%;
        margin: 0.05rem auto 0;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏超出部分 */
        text-overflow: ellipsis; /* 显示省略号 */
        text-align: center;
      }
    }
  }
}
</style>
